
<template>
  <div class="PeopCent_I">
    <div class="headNavs">
      <div class="headTilte">个人中心</div>
      <div class="headDetails">说明：服务顾问查看经验范围，个人账号密码管理与修改</div>
    </div>

    <div class="PersonalList">
      <div class="PersonalLeft">
        <div class="PersonalLeftTop">
          <div class="img">
            <img :src="Data_staff.full_avatar" alt="">
          </div>
          <div class="Name">{{Data_staff.realname}}</div>
          <div class="introduce">{{Data_staff.job}}</div>
          <div class="information">
            <div>站点：{{Data_business.site}}</div>
            <div>部门：{{Tit_name}}</div>
            <div>职务：{{Data_staff.job}}</div>
            <div>角色：{{Data_business.username}}</div>
            <div>入职时间：{{Data_business.entry_time}}</div>
          </div>
        </div>
        <div class="PersonalLeftBtn">
          <div >标签</div>
          <div class="labels">
            <span>很有可能的</span>
            <span>专注设计</span>
            <span>很有可能的</span>
            <span>很有可能的</span>
            <span>很有可能的</span>
            <span>很有可能的</span>
          </div>
        </div>
      </div>
      <div class="PersonalRight">
        <div class="PersonalRightTop">
          <div @click="Fwffw(0)" :class="idw == 0? 'divActive':''">服务商品</div>
          <div @click="Fwffw(1)" :class="idw == 1? 'divActive':''">账号设置</div>
        </div>
        <div class="PersonalRightBtn">
          <div v-if="idw == 0">
            <div class="Service" v-for="item in Btnnt" :key="item.value" :label="item.content" :value="item.value">
              <div class="ServiceName">{{item.name}}</div>
              <el-checkbox class="checkbox" v-for="item2 in item.goodsList" :key="item2.value"  v-model="item2.checked" @change="Chan_box(item2)">{{item2.goods_name}}</el-checkbox>
            </div>
            <div class="NavLiQuery" style="width:30px" @click="Sure_check()">确认</div>
          </div>
          <div v-if="idw == 1" style="padding-top: 20px;">
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">用户名</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Title" placeholder="请填写用户名" disabled=""></el-input>
              </div>
            </div>
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">新密码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="password1" placeholder="请输入新密码"></el-input>
                <span class="mima" v-if="idx == 1">新密码不能为空</span>
              </div>
            </div>
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">确认密码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="password2" placeholder="请确认密码" @blur="Blur"></el-input>
                <span class="mima" v-if="idy == 1">两次密码不同</span>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="NavLiQuery" @click="Btnbaocun">保存</div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "basetable",
  data() {
    return {
      Btnnt:[],
      BRR:[],
      Data_staff:{},
      Data_business:{},
      Tit_name:'',
      idw:0,
      idx:0,
      idy:0,
      Title:'张三',
      password1:'',
      password2:''
    };
  },
  mounted() {
    var that = this
    that.AAAAA()
    that.BBBB()
  },
  methods: {
    Blur(e) {
      var that = this
      var a11 = that.password1
      var a22 = that.password2
      if (a11 == a22) {
        that.idy = 2
      } else {
        that.idy = 1
      }
    },
    Sure_check() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/profile/saveGoods',
        data: {
          row:{
            goods_ids:that.BRR
          },
        },
      }).then(function (res) {
        if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.BBBB()
          }
      })
    },
    Chan_box(e) {
      var that = this
      var Arr = that.Btnnt
      var VRR = that.BRR
      for (let i = 0; i < Arr.length; i++) {
        const el_1 = Arr[i];
        for (let o = 0; o < el_1.goodsList.length; o++) {
          const el_2 = el_1.goodsList[o];
          if(e.goods_id == el_2.goods_id) {
            if (e.checked == true) {
              VRR.push(el_2.goods_id)
            } else {
              for (let u = 0; u < VRR.length; u++) {
                VRR[u];
                if (VRR[u] == el_2.goods_id) {
                  VRR.splice(u,1)
                }
              }
            }
          }
        }
      }
      that.BRR = VRR
    },
    Btnbaocun() {
      var that = this
      if (that.password1 == ''){
        that.idx = 1
        return false;
      }else{
        that.idx = 0
      }
      if (that.password1 != that.password2) {
        that.idy = 1
        return false;
      }else{
        that.idy = 0
      }
      axios({
          method: 'post',
          url: '/admin/general/profile/update',
          data: {
            row:{
              password:that.password1
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
          }
        })
    },
    Fwffw(e) {
      var that = this
      that.idw = e
    },
    AAAAA() {
      var that = this
      axios.get('/admin/general/profile/index',{
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');
          } else{
              that.Title = res.data.data.staff.realname
              that.Data_staff = res.data.data.staff
              that.Data_business = res.data.data.business
              that.Tit_name = res.data.data.staff.department.name
              console.log(that.Data)
          }
      })
    },

    BBBB() {
      var that = this
      axios.get('/admin/general/profile/goodsList',{
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');
          } else{
              that.Btnnt = res.data.data.categoryList
              that.BRR = res.data.data.goodsIds
          }
      })
    },
  },
};
</script>


<style scoped>
.PersonalList{
  display: flex;
  margin: 20px 1.5%;
  justify-content: space-between;
}
.PersonalLeft,
.PersonalRight{
  background-color: #fff;
  border-radius: 6px;
}
.PersonalLeft{
  width: 25%;
  padding: 44px 3%;
}
.PersonalRight{
  width: 67.5%;
}
.PersonalLeftTop{
  text-align: center;
}
.img{
  width: 104px;
  height: 104px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  background-color: #ccc;
}
.Name{
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin-top: 20px;
}
.introduce{
  font-family: PingFang SC;
  font-weight: 500;
  color: #8C8C8C;
  margin: 15px 0 30px;
}
.information{
  width: 84%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 16%;
}
.information div{
  font-size: 14px;
  width: 50%;
  font-weight: 500;
  color: #262626;
  margin-bottom: 15px;
}
.PersonalLeftBtn{
  border-top: 1px dashed #eee;
  padding-top: 20px;
  margin-top: 30px;
}
.labels{
  margin: 5px 0;
}
.labels span{
  display: inline-block;
  padding: 5px 8px;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-right: 8px;
  margin-top: 8px;
}
.PersonalRightTop{
  border-bottom: 1px solid #eee;
  display: flex;
}
.PersonalRightTop div{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-left: 42px;
  height: 56px;
  line-height: 56px;
  cursor: pointer;
}
.divActive{
  color: #1890FF !important;
  border-bottom: 3px solid #1890FF;
}
.PersonalRightBtn{
  padding-top: 20px;
  padding-left: 42px;
}
.ServiceName{
  font-size: 15px;
  font-weight: 700;
  margin: 10px 0;
}
.checkbox{
  width: 28.5%;
  margin-bottom: 8px !important;
}
.EditNewFormItemName{
  margin-bottom: 10px;
}
.EditNewFormItem{
  width: 33.3%;
  margin-bottom: 15px;
}
.NavLiQuery{
  margin-top: 30px;
  width: 68px;
}
.mima{
  display: inline-block;
  color: red;
  font-size: 13px;
  margin-top: 8px;
}
.NavLiQuery{
  margin-bottom: 20px;
}
</style>
